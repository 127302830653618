import React, { Component } from "react";
import "./navbarNetworkLeading.css";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navbarNetworkLeadingActions from "../../store/navbarNetworkLeading/actions";
export default class navbarNetworkLeading extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    const Id= 106;
    return (
      <div className="component-navbar-network-leading">
        <nav className="navbar navbar-expand-lg fixed-top  bg-navbar">
          <div className="container-fluid">
            <img src={logo} className="taille-logo-bakeli-navigation" />

            <ul className="nav navbar-nav padd-right-dep">
              <li class="nav-item dropdown menu-area">
                <a
                  class="nav-link dropdown-toggle pr-3 formation-color-txt"
                  href="#"
                  id="mega-one"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Formations
                </a>
                <div class="dropdown-menu mega-area" aria-labelledby="mega-one">
                  <div class="row">
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Programmation</h5>
                      <a href="#" class="dropdown-item">
                        - Développeur web frontend
                      </a>
                      <a href="#" class="dropdown-item">
                        - Développeur web backend
                      </a>
                      <a href="#" class="dropdown-item">
                        - Développeur mobile
                      </a>
                      <a href="#" class="dropdown-item">
                        - Développeur logiciel
                      </a>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Design</h5>
                      <a href="#" class="dropdown-item">
                        - Design d’interfaces utilisateur
                      </a>
                      <a href="#" class="dropdown-item">
                        - Design d’ expérience utilisateur
                      </a>
                      <a href="#" class="dropdown-item">
                        - Design graphique
                      </a>
                      <a href="#" class="dropdown-item">
                        - Montage vidéo
                      </a>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Marketing digital</h5>
                      <a href="#" class="dropdown-item">
                        - Design d’interfaces utilisateur
                      </a>
                      <a href="#" class="dropdown-item">
                        - Design d’ expérience utilisateur
                      </a>
                      <a href="#" class="dropdown-item">
                        - Design graphique
                      </a>
                      <a href="#" class="dropdown-item">
                        - Montage vidéo
                      </a>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Gestion de projet</h5>
                      <a href="#" class="dropdown-item">
                        - Scrum Master
                      </a>
                      <a href="#" class="dropdown-item">
                        - Product Owner
                      </a>
                      <a href="#" class="dropdown-item">
                        - Testteur Logiciel
                      </a>
                      <a href="#" class="dropdown-item">
                        - Chef de projet
                      </a>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Bureautique</h5>
                      <a href="#" class="dropdown-item">
                        - Rédiger son CV
                      </a>
                      <a href="#" class="dropdown-item">
                        - Rédiger un rapport de stage
                      </a>
                      <a href="#" class="dropdown-item">
                        - Rédiger un compte-rendu
                      </a>
                      <a href="#" class="dropdown-item">
                        - Rédiger un projet
                      </a>
                    </div>
                    <div class="col-sm-6 col-lg-2">
                      <h5 className="txt-menu-drop-lead">Anglais</h5>
                      <a href="#" class="dropdown-item">
                        - Se présen en Anglais
                      </a>
                      <a href="#" class="dropdown-item">
                        - Discuter en Anglais
                      </a>
                      <a href="#" class="dropdown-item">
                        - Faire son CV en Anglais
                      </a>
                      <a href="#" class="dropdown-item">
                        - Rédiger un mail en Anglais
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <input type="checkbox" id="check" />
            <label className="dep-icon-menu" for="check">
                  <FeatherIcon icon="menu" id="sidebar_btn"/>
              </label>
              
            <div className="mr-3 button__wrap">
                      <a href={`https://cutt.ly/9esvpQfB`}>
                        <button className="btn-theme-gradient text-up w-full">Inscription pour la rentrée de Bakeli</button>
                        </a>

                        </div>
                    
              {/* <ul className="navbar-nav navbar-right">
                <li className="nav-item position-item con-color">
                  <Link to="/connexion">
                    <a
                      className="nav-link cache-responsive"
                      aria-current="page"
                      href="/connexion"
                    > 
                      Connexion
                    </a>
                  </Link>
                </li>
                <li className="nav-item position-item inscrip-color">
                  <Link to="/inscription">
                    <a
                      className="nav-link cache-responsive"
                      aria-current="page"
                      href="#"
                    >
                      Inscription
                    </a>
                  </Link>
                </li>
              </ul> */}
            {/* </div> */}
          </div>
        </nav>
      </div>
    );
  }
}
// export default connect(
//     ({ navbarNetworkLeading }) => ({ ...navbarNetworkLeading }),
//     dispatch => bindActionCreators({ ...navbarNetworkLeadingActions }, dispatch)
//   )( navbarNetworkLeading );
